export const config = {
  environment: 'prod',
  b2bWebApi: 'https://b2bwebapi-dev.viggo.io',
  trackingServiceUrl: 'https://viggo-services-dev.herokuapp.com',
  appVersion: '1.0.0',
  firebase: {
    projectId: 'viggo-b2b-web-dev',
    appId: '1:844537052552:web:efedaab3e5155006170e3d',
    storageBucket: 'viggo-b2b-web-dev.appspot.com',
    apiKey: 'AIzaSyBAMyaz_2li47C0lqlQZEXvt_mRHVuxeUw',
    authDomain: 'viggo-b2b-web-dev.firebaseapp.com',
    messagingSenderId: '844537052552',
    measurementId: 'G-G4Q6ER0DDN',
  },
  intercomAppId: 'nzntab7c',
  fcUrl: 'https://fc-dev.viggo.com/',
  mapbox: {
    accessToken: 'pk.eyJ1IjoidmlnZ28tZGV2IiwiYSI6ImNrMmhsbHZsYTA3MXczY29lb3UxaWdzbTIifQ.x9EjmGO-2J6YBU9A4vQReg',
    style: 'mapbox://styles/mapbox/dark-v11',
    lat: 55.676111,
    lng: 12.568333,
  },
};
