import { createAction, props } from '@ngrx/store';
import { AgentAggregate, AppConfig, ExternalAccountManagerAggregate } from 'src/app/shared/models/firestore.model';

export const layoutHandsetChanged = createAction('[Auth] Layout handset changed', props<{ isHandset: boolean }>());

export const loginButtonClicked = createAction(
  '[Auth] Login button clicked',
  props<{ email: string; password: string }>(),
);

export const loginFinished = createAction('[Auth] Login finished');

export const signUpButtonClicked = createAction(
  '[Auth] Sign up button clicked',
  props<{ email: string; password: string }>(),
);

export const signUpFinished = createAction('[Auth] Sign up finished');

export const createBusinessButtonClicked = createAction(
  '[Auth] Create business button clicked',
  props<{ countryCode: string; name: string; companyCode: string; companySize: string }>(),
);

export const createBusinessFinished = createAction('[Auth] Create business finished');

export const passwordResetButtonClicked = createAction(
  '[Auth] Password reset button clicked',
  props<{ email: string }>(),
);

export const sendEmailVerificationButtonClicked = createAction('[Auth] Send email verification button clicked');

export const authStateChanged = createAction('[Auth] Auth state changed', props<{ uid: string }>());

export const tokenChanged = createAction('[Auth] Token changed', props<{ token: string }>());

export const logoutClicked = createAction('[Auth] Logout clicked');
export const logoutFinished = createAction('[Auth] Logout finished');

export const externalAccountManagerAggregateChanged = createAction(
  '[Auth] External account manager changed',
  props<{ externalAccountManagerAggregate: ExternalAccountManagerAggregate }>(),
);

export const agentAggregateChanged = createAction('[Auth] Agent changed', props<{ agentAggregate: AgentAggregate }>());

export const forgotPasswordDestroy = createAction('[Auth] Forgot password destroy');

export const passwordResetSuccess = createAction('[Auth] Password reset success');

export const passwordResetFailure = createAction('[Auth] Password reset failure');

export const appConfigChanged = createAction(
  '[Auth] App config show rides changed',
  props<{ appConfig: AppConfig }>(),
);
