import { createReducer, on } from '@ngrx/store';
import { AgentAggregate, AppConfig, ExternalAccountManagerAggregate } from 'src/app/shared/models/firestore.model';
import * as AuthActions from './auth.actions';
import { jwtDecode } from 'jwt-decode';
import { CustomToken } from '../../shared/models/token.model';

export const authFeatureKey = 'auth';

export interface State {
  isHandset: boolean;
  uid: string;
  token: CustomToken;
  loginInProgress: boolean;
  signupInProgress: boolean;
  sendPasswordResetInProgress: boolean;
  createBusinessInProgress: boolean;
  externalAccountManagerAggregate: ExternalAccountManagerAggregate;
  agentAggregate: AgentAggregate;
  forgotPasswordStepIndex: number;
  appConfig: AppConfig;
}

export const initialState: State = {
  isHandset: false,
  uid: undefined,
  token: undefined,
  loginInProgress: false,
  signupInProgress: false,
  createBusinessInProgress: false,
  externalAccountManagerAggregate: undefined,
  agentAggregate: undefined,
  sendPasswordResetInProgress: false,
  forgotPasswordStepIndex: 0,
  appConfig: undefined,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.layoutHandsetChanged, (state, action) => ({
    ...state,
    isHandset: action.isHandset,
  })),
  on(AuthActions.authStateChanged, (state, action) => ({
    ...state,
    uid: action.uid,
  })),
  on(AuthActions.tokenChanged, (state, action) => ({
    ...state,
    token: jwtDecode(action.token) as CustomToken,
  })),
  on(AuthActions.loginButtonClicked, state => ({
    ...state,
    loginInProgress: true,
  })),
  on(AuthActions.loginFinished, state => ({
    ...state,
    loginInProgress: false,
  })),
  on(AuthActions.signUpButtonClicked, state => ({
    ...state,
    signupInProgress: true,
  })),
  on(AuthActions.signUpFinished, state => ({
    ...state,
    signupInProgress: false,
  })),
  on(AuthActions.createBusinessButtonClicked, state => ({
    ...state,
    createBusinessInProgress: true,
  })),
  on(AuthActions.createBusinessFinished, state => ({
    ...state,
    createBusinessInProgress: false,
  })),
  on(AuthActions.externalAccountManagerAggregateChanged, (state, action) => ({
    ...state,
    externalAccountManagerAggregate: action.externalAccountManagerAggregate,
  })),
  on(AuthActions.agentAggregateChanged, (state, action) => ({
    ...state,
    agentAggregate: action.agentAggregate,
  })),
  on(AuthActions.passwordResetButtonClicked, state => ({
    ...state,
    sendPasswordResetInProgress: true,
  })),
  on(AuthActions.passwordResetSuccess, state => ({
    ...state,
    sendPasswordResetInProgress: false,
    forgotPasswordStepIndex: 1,
  })),
  on(AuthActions.passwordResetFailure, state => ({
    ...state,
    sendPasswordResetInProgress: false,
  })),
  on(AuthActions.forgotPasswordDestroy, state => ({
    ...state,
    forgotPasswordStepIndex: 0,
  })),
  on(AuthActions.logoutFinished, state => ({
    ...state,
    uid: null,
    token: null,
    agentAggregate: null,
    externalAccountManagerAggregate: null,
    appConfig: null,
  })),
  on(AuthActions.appConfigChanged, (state, action) => ({
    ...state,
    appConfig: action.appConfig,
  })),
);
