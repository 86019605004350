import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { config } from 'src/environments/config';
import { EnvironmentType } from 'src/app/shared/models/environment.model';
import { CountryCodeVM } from 'src/app/shared/models/country-code.model';
import { AppFeatureToggle, NearbyDriverLimitConfig } from 'src/app/shared/models/firestore.model';

export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const selectIsHandset = createSelector(selectAuthState, authState => authState.isHandset);
export const selectUid = createSelector(selectAuthState, authState => authState.uid);
export const selectLoggedIn = createSelector(selectAuthState, authState => !!authState.uid);
export const selectToken = createSelector(selectAuthState, authState => authState.token);
export const selectUserRoles = createSelector(selectToken, token => token?.roles);
export const selectIsExternalAccountManager = createSelector(selectUserRoles, roles =>
  roles?.includes('b2b-web.external_account_manager'),
);
export const selectIsAgent = createSelector(selectUserRoles, roles => roles?.includes('b2b-web.agent'));
export const selectLoginInProggress = createSelector(selectAuthState, authState => authState.loginInProgress);
export const selectSignupInProgress = createSelector(selectAuthState, authState => authState.signupInProgress);
export const selectCreateBusinessInProgress = createSelector(
  selectAuthState,
  authState => authState.createBusinessInProgress,
);
export const selectExternalAccountManagerAggregate = createSelector(
  selectAuthState,
  authState => authState.externalAccountManagerAggregate,
);
export const selectAgentAggregate = createSelector(selectAuthState, authState => authState.agentAggregate);
export const selectBusinessId = createSelector(
  selectAgentAggregate,
  selectExternalAccountManagerAggregate,
  (agentAggregate, externalAccountManagerAggregate) =>
    agentAggregate?.agent?.business_id ?? externalAccountManagerAggregate?.external_account_manager?.business_id,
);
export const selectFcUserEmail = createSelector(selectToken, token => token?.fc_user_email);
export const selectFcUserId = createSelector(selectToken, token => token?.fc_user_id);
export const selectEmail = createSelector(
  selectFcUserEmail,
  selectExternalAccountManagerAggregate,
  (agentEmail, externalAccountManagerAggregate) => agentEmail ?? externalAccountManagerAggregate?.user?.email,
);
export const selectPasswordResetInProgress = createSelector(
  selectAuthState,
  authState => authState.sendPasswordResetInProgress,
);
export const selectForgotPasswordStepIndex = createSelector(
  selectAuthState,
  authState => authState.forgotPasswordStepIndex,
);
export const selectAuthStepIndex = createSelector(selectExternalAccountManagerAggregate, aggregate => {
  if (aggregate?.user?.email_verified) {
    return 2;
  } else if (aggregate?.user) {
    return 1;
  } else {
    return 0;
  }
});
export const selectEnvConfig = createSelector(
  selectAuthState,
  () => config.environment === EnvironmentType.DEVELOPEMENT,
);
export const supportedCountryCodes = createSelector(
  selectAuthState,
  () =>
    [
      { code: 'AE', name: 'United Arab Emirates', center: { lat: 23.424076, lng: 53.847818 } },
      { code: 'CA', name: 'Canada', center: { lat: 56.130366, lng: -106.346771 } },
      { code: 'DK', name: 'Denmark', center: { lat: 55.676098, lng: 12.568337 } },
      { code: 'LT', name: 'Lithuania', center: { lat: 54.687157, lng: 25.279652 } },
      { code: 'NO', name: 'Norway', center: { lat: 59.911491, lng: 10.757933 } },
    ] as CountryCodeVM[],
);

export const selectAppConfig = createSelector(selectAuthState, authState => authState.appConfig);
export const selectShowRidesConfig = createSelector(selectAppConfig, appConfig => appConfig?.['show_rides']);
export const selectNearbyDriversConfig = createSelector(
  selectAppConfig,
  appConfig => appConfig?.['show_nearby_drivers'] as NearbyDriverLimitConfig,
);

export const selectIsRidesMenuIsVisible = createSelector(
  selectBusinessId,
  selectShowRidesConfig,
  selectIsAgent,
  (businessId, showRides, isAgent) => isAgent && showRides && isFeatureEnabledForBusiness(showRides, businessId),
);

export const isShowNearbyDriversEnabled = createSelector(
  selectBusinessId,
  selectNearbyDriversConfig,
  (businessId, showNearbyDrivers) => isFeatureEnabledForBusiness(showNearbyDrivers, businessId),
);

export const selectShowNearbyDriversLimit = createSelector(selectNearbyDriversConfig, selectNearbyDriversConfig => {
  return selectNearbyDriversConfig?.limit ?? 10;
});

export const selectNearbyDriversFetchInterval = createSelector(selectNearbyDriversConfig, selectNearbyDriversConfig => {
  return selectNearbyDriversConfig?.interval ?? 30000;
});

function isFeatureEnabledForBusiness(featureToggle: AppFeatureToggle, businessId: string): boolean | null {
  if (!featureToggle) return null;
  return !!featureToggle && !!businessId
    ? featureToggle.is_enabled_for_all || featureToggle.enabled_business_ids?.indexOf(businessId) >= 0
    : null;
}
